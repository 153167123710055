import logo from '../Pictures/logo.jpg'

export const News =[

/* Template
    {
        id: 1,
        image: logo,
        headline: 'General Meeting March 16 2024',
        date: 'March 16 2024',
        time: '18:00',
        sort: new Date('March 16 2024'),
        location:'Via Zoom',
        details:<ul>
            <li><h3>Agenda 1</h3></li>
            <br/>
            <li><h3>Agenda 2</h3></li>
        </ul>
    },
*/

{
    id: 1,
    image: logo,
    headline: 'Ghana Union supporting flood victims in the Czech Republic September 2024',
    date: 'September 2024',
    short: 'In September 2024, the Ghana Union in the Czech Republic made a financial donation to support flood victims in the Czech Republic through the agency People in Need.',
    time: '12:00',
    sort: new Date('December 31 2025'),
    details:<p>In September 2024, the Ghana Union in the Czech Republic made a financial donation to support flood victims in the Czech Republic through the agency People in Need.
<br/>
    This initiative aligns with our humanitarian mission, which extends beyond our Union members to the broader community in which we reside. 
<br/>    
    Additionally, the Ghana Union is dedicated to engaging in various activities to facilitate the integration of its members into Czech society.</p>
},


]