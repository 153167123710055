import React from 'react'
import { useParams } from 'react-router-dom'
import { Divider } from 'semantic-ui-react'
import bannerPic1 from '../Pictures/banner1.jpg'


export default function Forms() {

    const {id} = useParams()

    let bannerStyle ={
        backgroundImage: `url(${bannerPic1})`,
      }


  return (
    <>
          <div className='pageBanner' style={bannerStyle}>
        <div className='pageBannerText'>
          <Divider horizontal style={{color:'white'}}><h1>Bowling Form</h1></Divider>
          <br/>

        </div>
      </div>

      
    </>
  )
}
